import React from 'react'
import PoweredBy from '../PoweredBy/PoweredBy'
import logo from 'src/static/svg/logo-w-new.svg'
import fse from 'src/static/images/fse+.png'
import './Footer.sass'

const Footer = () => {
  return (
    <footer id="colophon">
      <PoweredBy />
      <section>
        <img className="logo" src={logo} alt="Nuevos retos logo" />
        <div className='fse_container'>
          <img className="fse_banner" src={fse} alt="fse+" />
        </div>
      </section>
    </footer>
  )
}

export default Footer
