import React from 'react'
import { FormattedMessage } from 'react-intl'
import images from '../images'

import './Banner.sass'

const Banner = ({ onView }) => {

  const bannerCards = [
    'face',
    'intensive',
    'ceo',
    'experts',
    'free',
    'mentors'
  ]

  return (
    <section id="banner" className={onView ? 'onView' : ''}>
      <ul className="container">
        {bannerCards.map(b =>
          <li key={b}>
            <img src={images[b]} alt={`banner icon ${b}`} />
            <p>
              <FormattedMessage id={`home.banner-${b}`} />
            </p>
          </li>
        )}
      </ul>
    </section>
  )
}

export default Banner
