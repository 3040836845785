import React from 'react'
import { FormattedMessage } from 'react-intl'
import './Intro.sass'

const Intro = ({ onView }) => {
  return (
    <section id="intro" className={onView ? 'onView' : ''}>
      <div className="container">
        <div className="video">
          {onView && (
            <iframe
              src="https://www.youtube.com/embed/oHU6joHWLlc"
              title="DxB video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
        <div className="content">
          <h2 className="subtitle">
            <FormattedMessage id="home.general.subtitle" />
          </h2>
          <h1 className="title">
            <FormattedMessage id="home.intro.title" />
          </h1>
          <p>
            <FormattedMessage id="home.intro.text" />
          </p>
        </div>
      </div>
    </section>
  )
}

export default Intro
