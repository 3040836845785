import React from 'react'
import { FormattedMessage } from 'react-intl'
import logos from './companiesLogos'

import './Companies.sass'

const Companies = ({ onView }) => {

  const companiesLogos = [
    ['aws', 'https://aws.amazon.com/'],
    ['ibm', 'https://www.ibm.com/es-es'],
    ['microsoft', 'https://www.microsoft.com/'],
    ['bhub', 'https://www.bhub.es/'],
    ['bluco', 'https://bluco.es/'],
    ['dataqu', 'https://dataqu.ai/'],
    ['digital_addiction', 'https://digitaladdiction.es/'],
    ['digital_tactics', 'https://digitaltactics360.com/'],
    ['dunkin', 'https://www.dunkin.nl/'],
    ['edefense', 'https://edefense.es/'],
    ['garrigues', 'https://www.garrigues.com/'],
    ['genetsis_group', 'https://www.genetsisgroup.com/'],
    ['google_cloud', 'https://cloud.google.com/?hl=es'],
    ['honest_strategy', 'https://honeststrategy.com/'],
    ['igeneris', 'https://www.igeneris.com/'],
    ['integrarobot', 'https://www.integrarobot.com/'],
    ['improven', 'https://improven.com/'],
    ['innova', 'https://www.innovaexport.com/'],
    ['iristrace', 'https://iristrace.com/'],
    ['lideres_digitales', 'https://www.lideresydigitales.com/'],
    ['making_science', 'https://www.makingscience.com/'],
    ['minsait', 'https://www.minsait.com/es'],
    ['nifty', 'https://niftyexport.online/en/'],
    ['novalo', 'https://novalo.com/'],
    ['numericco', 'https://www.numericco.com/'],
    ['product_hackers', 'https://producthackers.com/es/'],
    ['proportione', 'https://proportione.com/'],
    ['roundcubers', 'https://www.roundcubers.com/'],
    ['sakudarte', 'https://sakudarte.com/'],
    ['shakers', 'https://www.shakersworks.com/'],
    ['sisteplant', 'https://www.sisteplant.com/'],
    ['stratio', 'https://www.stratio.com/home'],
    ['transparent_edge', 'https://www.transparentedge.eu/'],
    ['wam', 'https://wam.global/'],
  ]

  return (
    <section id="companies" className={onView ? 'onView' : ''}>
      <div className="container">
        <h1 className="title">
          <FormattedMessage id="home.companies-title" />
        </h1>
        <h2>
          <FormattedMessage id="home.companies-subtitle" />
        </h2>
        <ul className="companies-grid">
          {companiesLogos.map(([company, url]) => (
            <li key={company}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={logos[company]} alt={`icon ${company}`} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Companies
