import React from 'react'
import { FormattedMessage } from 'react-intl'
import images from '../images'

import './CEO.sass'

const CEO = ({onView}) => {

  const questCards = [
    'digitization',
    'innovation',
    'excellence',
    'growing',
    'technology',
    'leadership'
  ]

  return (
    <section id="ceo-quests" className={onView ? 'onView' : ''}>
      <div className="container">
        <h1 className="title">
          <FormattedMessage id="home.ceo-quests.title" />
        </h1>
        <ul className="ceo-quests-grid">
          {questCards.map(q =>
            <li key={q}>
              <img src={images[q]} alt={`icon ${q}`} />
              <h2>
                <FormattedMessage id={`home.ceo-quests.quest-${q}.title`} />
              </h2>
              <p>
                <FormattedMessage id={`home.ceo-quests.quest-${q}.text`} />
              </p>
            </li>
          )}
        </ul>
      </div>
    </section>
  )
}

export default CEO
