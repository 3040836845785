import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typed from 'react-typed'

import './Typewriter.sass'

const Typewriter = () => {

  return (
    <div className="typewriter">
      <h1>
        <FormattedMessage id="home.hero-banner.title" />
      </h1>
      <h2>
        <Typed
          strings={[
            'Transformación digital',
            'Innovación',
            'Excelencia operativa',
            'Crecimiento',
            'Tecnología responsable',
          ]}
          typeSpeed={40}
          backSpeed={50}
          loop />
      </h2>
    </div>
  )
}

export default Typewriter
