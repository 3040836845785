import React from "react";
import { FormattedMessage } from "react-intl";
import speakersImages from "./SpeakersPhotos";

import "./Speakers.sass";

const Speakers = ({ onView }) => {

  const speakersByCategory = {
    "Coordinador": [
      ['joseLuisAlonso', 'José Luis Alonso', 'Director Académico', 'DigitalXBorder ', 'https://www.linkedin.com/in/jos%C3%A9-luis-alonso-gallardo-5b2ba3b/'],
    ],
    "Transformación Digital": [
      ['cesarT', 'César Tello', 'Director General', 'Adigital', 'https://www.linkedin.com/in/cesartello/'],
      ['sergio', 'Sergio Gordillo', 'CEO', 'Improven', 'https://www.linkedin.com/in/sergio-gordillo-martinez-excelencia-estrategia-empresarial/'],
      ['cesar', 'César Mariel', 'Fundador', 'Iristrace', 'https://www.linkedin.com/in/cmariel/'],
      ['javierM', 'Javier Martín', 'Fundador', 'Integrarobot', 'https://www.linkedin.com/in/liderarmente/'],
      ['javierMartinez', 'Javier Martinez', 'Head of Customer Engineering', 'Google Cloud', 'https://www.linkedin.com/in/jamarmu/']
    ],
    "Innovación": [
      ['joseLuisPulpon', 'José Luis Pulpón', 'Director General', 'Making Science', 'https://www.linkedin.com/in/jos%C3%A9-luis-pulp%C3%B3n-98b5601/'],
      ['hector', 'Héctor Robles', 'Fundador', 'Honest Strategy', 'https://www.linkedin.com/in/hectorr0bles/'],
      ['oscar', 'Óscar Méndez', 'Chairman and Founding Partner', 'StratioBD', 'https://www.linkedin.com/in/oscarmendezsoto/'],
      ['carlos', 'Carlos García-Castellano', 'Senior Advisor', 'Dataqu', 'https://www.linkedin.com/in/carlosgcg/'],
      ['joseLuisVal', 'José Luis del Val', 'Presidente Deusto Forum', 'Universidad de Deusto', 'https://www.linkedin.com/in/joseluisdelval/'],
      ['daniel', 'Daniel Puertolas', 'CEO y Cofundador', 'Numericco', 'https://www.linkedin.com/in/danipuertolas/'],
      ['jose', 'José López García de Leániz', 'Managing Partner', 'Igéneris', 'https://www.linkedin.com/in/jlgleaniz/'],
      ['leticia', 'Leticia Gómez', 'Senior Manager Corporate Strategy', 'Minsait', 'https://www.linkedin.com/in/leticia-g%C3%B3mez-rivero-59194317/']
    ],
    "Excelencia Operativa": [
      ['lucia', 'Lucía Martínez', 'VP de IT', 'Wam Global', 'https://www.linkedin.com/in/lucia-martinez-alvarez-cio/'],
      ['javier', 'Javier Cuervo', 'Fundador', 'Proportione', 'https://www.linkedin.com/in/javiercuervo/'],
      ['ana', 'Ana Santiago', 'Presidenta', 'Sisteplant', 'https://www.linkedin.com/in/ana-santiago-g-bret%C3%B3n-52414611/'],
      ['arturo', 'Arturo Guerrero', 'Cloud Sales Manager', 'IBM', 'https://www.linkedin.com/in/arturoguerrero1905/'],
      ['alberto', 'Alberto Turégano', 'Socio', 'Líderes y Digitales', 'https://www.linkedin.com/in/albertotureganoschirmer/'],
      ['tirso', 'Tirso Maldonado', 'Socio Fundador', 'Roundcubers', 'https://www.linkedin.com/in/tirsomaldonado/']
    ],
    "Crecimiento I": [
      ['biladi', 'Biladi Luján', 'Directora', 'Sakudarte', 'https://www.linkedin.com/in/biladilujan/'],
      ['monica', 'Mónica Díaz-Ponte', 'Directora General', 'Digital Addiction', 'https://www.linkedin.com/in/monicadiazponte/'],
      ['eduardo', 'Eduardo Canelles', 'CEO y fundador', 'DigitalTactics', 'https://www.linkedin.com/in/marketinginteligenciaartificial/'],
      ['alejandro', 'Alejandro Orviz', 'Fundador', 'Bluco', 'https://www.linkedin.com/in/aleorviz/'],
      ['fernando', 'Fernando Aparicio', 'Fundador y CEO', 'Nifty Export', 'https://www.linkedin.com/in/fernando-aparicio-varas/'],
      ['luis', 'Luis Díaz del Dedo', 'CEO y Cofundador', 'Product Hawkers', 'https://www.linkedin.com/in/luisdiazdeldedo/']
    ],
    "Crecimiento II": [
      ['fernandoG', 'Fernando García', 'Managing Director', 'B!Hub', 'https://www.linkedin.com/in/fernandogarciatoledano/'],
      ['marta', 'Marta Córdoba', 'Account Manager', 'AWS', 'https://www.linkedin.com/in/martacordoba/'],
      ['dori', 'Dori López', 'CEO', 'Innova Digital Export', 'https://www.linkedin.com/in/dorilopez/'],
      ['paloma', 'Paloma Velázquez', 'Business Growth Manager', 'Genetsis', 'https://www.linkedin.com/in/pvelazquez/'],
      ['nacho', 'Nacho Carnés', 'Head of Strategy & Data Strategy', 'Dunkin', 'https://www.linkedin.com/in/nachocarnes/'],
      ['valentin', 'Valentín Barrantes', 'Fundador y CEO', 'Nóvalo', 'https://www.linkedin.com/in/valentinbarrantes/']
    ],
    "Digitalización Responsable": [
      ['justo', 'Justo Hidalgo', 'Chief AI Officer', 'Adigital', 'https://www.linkedin.com/in/justohidalgo/'],
      ['alvaro', 'Álvaro de la Cueva', 'Socio', 'Garrigues', 'https://www.linkedin.com/in/alvarodelacueva/'],
      ['nuri', 'Nuri Demirci', 'Principal Product Manager', 'Microsoft', 'https://www.linkedin.com/in/nuri-demirci-lopez/'],
      ['fermin', 'Fermín Manzanedo', 'Director de Operaciones', 'Transparent Edge', 'https://www.linkedin.com/in/fmanzanedowebperformanceit/'],
      ['tiffany', 'Tiffany de la Torre', 'Cofundadora', 'Edefense', 'https://www.linkedin.com/in/tiffany-delatorre-garcia/']
    ],
    "La Empresa del Mañana": [
      ['eduardoL', 'Eduardo Lazcano', 'Consultor', '', 'https://www.linkedin.com/in/eduardolazcano/'],
      ['joseLuisAlonso', 'José Luis Alonso', 'Director Académico', 'DigitalXBorder ', 'https://www.linkedin.com/in/jos%C3%A9-luis-alonso-gallardo-5b2ba3b/'],
      ['jaime', 'Jaime Castillo', 'Cofundador', 'Shakers', 'https://www.linkedin.com/in/castilloparagesjaime/']
    ]
  };

  return (
    <section id="speakers" className={onView ? "onView" : ""}>
      <div className="container">
        <h1 className="title">
          <FormattedMessage id="speakers.title" />
        </h1>
        <h2>
          <FormattedMessage id="speakers.subtitle" />
        </h2>
        <h3 className="category-title">  </h3>

        {Object.entries(speakersByCategory).map(([category, speakers]) => (
          <div key={category} className="speaker-category">
            <h3 className="category-title">{category}</h3>
            <ul className="speakers">
              {speakers.map(([speaker, name, job, company, linkedIn]) => (
                <li key={speaker} className="speaker-item">
                  <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                    <img src={speakersImages[speaker]} alt={speaker} loading="lazy"/>
                  </a>
                  <div className="speaker-info">
                    <p className="name">{name}</p>
                    <p className="job">{job}</p>
                    <p className="company">{company}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Speakers;
