import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Requirements.sass'

const Requirements = ({ onView }) => {

  return (
    <section id="requirements" className={onView ? 'onView' : ''}>
      <h1 className="title">
        <FormattedMessage id="home.requirements.title" />
      </h1>
      <div className="container">
        <ul className="requirements-list">
          <li className="requirement">
            <span className="icon icon-user" />
            <span><FormattedMessage id="home.requirements.requirement-1" /></span>
          </li>
          <li className="requirement">
            <span className="icon icon-euro" />
            <span><FormattedMessage id="home.requirements.requirement-2" /></span>
          </li>
          <li className="requirement">
            <span className="icon icon-sphere" />
            <span><FormattedMessage id="home.requirements.requirement-3" /></span>
          </li>
          <li className="requirement">
            <span className="icon icon-messagge-love" />
            <span><FormattedMessage id="home.requirements.requirement-4" /></span>
          </li>
          <li className="requirement">
            <span className="icon icon-calendar-2" />
            <span><FormattedMessage id="home.requirements.requirement-5" /></span>
          </li>
          <li className="requirement">
            <span className="icon icon-dollar" />
            <span><FormattedMessage id="home.requirements.requirement-6" /></span>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Requirements
