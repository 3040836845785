import adigital from 'src/static/svg/CompaniesLogos/logo_adigital.svg'
import aws from 'src/static/svg/CompaniesLogos/logo_aws.svg'
import bhub from 'src/static/svg/CompaniesLogos/logo_bhub.svg'
import bluco from 'src/static/svg/CompaniesLogos/logo_bluco.svg'
import dataqu from 'src/static/svg/CompaniesLogos/logo_dataqu.svg'
import digital_addiction from 'src/static/svg/CompaniesLogos/logo_digital-addiction.svg'
import digital_tactics from 'src/static/svg/CompaniesLogos/logo_digital-tactics.svg'
import dunkin from 'src/static/svg/CompaniesLogos/Dunkin.svg'
import edefense from 'src/static/svg/CompaniesLogos/logo_edefense.svg'
import garrigues from 'src/static/svg/CompaniesLogos/logo_garrigues.svg'
import genetsis_group from 'src/static/svg/CompaniesLogos/logo_genetsis-group.svg'
import google from 'src/static/svg/CompaniesLogos/logo_google.svg'
import google_cloud from 'src/static/svg/CompaniesLogos/Google_Cloud_logo.svg'
import honest_strategy from 'src/static/svg/CompaniesLogos/logo_honest-strategy.svg'
import ibm from 'src/static/svg/CompaniesLogos/logo_ibm.svg'
import igeneris from 'src/static/svg/CompaniesLogos/logo_igeneris.svg'
import integrarobot from 'src/static/svg/CompaniesLogos/logo_integrarobot.svg'
import improven from 'src/static/svg/CompaniesLogos/logo_improven.svg'
import innova from 'src/static/svg/CompaniesLogos/logo_innova-export.svg'
import iristrace from 'src/static/svg/CompaniesLogos/logo_iristrace.svg'
import lideres_digitales from 'src/static/svg/CompaniesLogos/logo_lideres-y-digitales.svg'
import making_science from 'src/static/svg/CompaniesLogos/logo_making-science.svg'
import microsoft from 'src/static/svg/CompaniesLogos/logo_microsoft.svg'
import minsait from 'src/static/svg/CompaniesLogos/logo_minsait.svg'
import nifty from 'src/static/svg/CompaniesLogos/logo_nifty.svg'
import novalo from 'src/static/svg/CompaniesLogos/logo_novalo.svg'
import numericco from 'src/static/svg/CompaniesLogos/logo_numericco.svg'
import product_hackers from 'src/static/svg/CompaniesLogos/logo_product-hackers.svg'
import proportione from 'src/static/svg/CompaniesLogos/logo_proportione.svg'
import roundcubers from 'src/static/svg/CompaniesLogos/logo_roundcubers.svg'
import sakudarte from 'src/static/svg/CompaniesLogos/logo_sakudarte.svg'
import shakers from 'src/static/svg/CompaniesLogos/logo_shakers.svg'
import sisteplant from 'src/static/svg/CompaniesLogos/logo_sisteplant.svg'
import stratio from 'src/static/svg/CompaniesLogos/logo_stratio.svg'
import transparent_edge from 'src/static/svg/CompaniesLogos/logo_transparent-edge.svg'
import wam from 'src/static/svg/CompaniesLogos/logo_wam.svg'

export default {
    adigital,
    aws,
    bhub,
    bluco,
    dataqu,
    digital_addiction,
    digital_tactics,
    dunkin,
    edefense,
    garrigues,
    genetsis_group,
    google,
    google_cloud,
    honest_strategy,
    ibm,
    igeneris,
    integrarobot,
    improven,
    innova,
    iristrace,
    lideres_digitales,
    making_science,
    microsoft,
    minsait,
    nifty,
    novalo,
    numericco,
    product_hackers,
    proportione,
    roundcubers,
    sakudarte,
    shakers,
    sisteplant,
    stratio,
    transparent_edge,
    wam
}
