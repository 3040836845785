import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import data from 'src/data'
import Loading from '../Loading/Loading'

import './Register.sass'

const Register = ({ onView = false }) => {
  const { cities } = data
  const cityNames = cities.map(c => c.name)
  const steps = ['pre-registration', 'preselection', 'interview', 'confirmation']
  const [status, setStatus] = useState('')
  const [formHow, setFormHow] = useState('')
  const [formLastName, setFormLastName] = useState('')
  const [formName, setFormName] = useState('')
  const [formPhone, setformPhone] = useState('')
  const [formMail, setFormMail] = useState('')
  const [formJob, setFormJob] = useState('')
  const [formCompany, setFormCompany] = useState('')
  const [formNIF, setFormNIF] = useState('')
  const [formCity, setFormCity] = useState('')
  const [formCompanyPhrase, setFormCompanyPhrase] = useState('')
  const [formWeb, setFormWeb] = useState('')
  const [formBilling, setFormBilling] = useState('')
  const [formExport, setFormExport] = useState('')
  const [formEmployees, setFormEmployees] = useState('')
  const [formExportCountries, setFormExportCountries] = useState('')
  const [formCanBuyOnline, setFormCanBuyOnline] = useState(0)
  const [formBusinessModel, setFormBusinessModel] = useState(0)
  const [formAttendingCity, setFormAttendingCity] = useState(0)
  const [formComments, setFormComments] = useState('')
  const [formCheckbox, setFormCheckbox] = useState(false)
  const buyOnline = [
    "Si",
    "No",
    "En proceso"
  ]
  const businessModel = [
    "B2B (Servicio o venta directa a otra empresa)",
    "B2C (Servicio o venta directa al consumidor)",
    "B2B2C (Tanto servicio o venta a otra empresa, como directamente al consumidor)"
  ]

  const intl = useIntl()

  const fetchApiSubmit = async (event) => {
    event.preventDefault()
    if (status === 'loading') return
    setStatus('loading')

    try {
      const res = await fetch("/api/submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactStatus: formHow,
          contactSurnames: formLastName,
          contactName: formName,
          contactPhone: formPhone,
          contactEmail: formMail,
          contactPosition: formJob,
          companyName: formCompany,
          companySector: formNIF,
          contactCity: formCity,
          contactExperience: formCompanyPhrase,
          companyWeb: formWeb,
          companyBilling: formBilling,
          companyExport: formExport,
          companyEmployees: formEmployees,
          companyExportCountries: formExportCountries,
          canBuyOnline: buyOnline[formCanBuyOnline - 1],
          businessModel: businessModel[formBusinessModel - 1],
          attendingCity: cityNames[formAttendingCity - 1],
          contactComments: formComments,
          communication: typeof formCheckbox === "boolean" ? formCheckbox : false
        })
      })
      console.log(res.status)
      setStatus('success')
      const com = typeof formCheckbox === "boolean" ? formCheckbox : false
      console.log(com)
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }

  const form =
    <form onSubmit={fetchApiSubmit} className='formContainer'>
      <h1 className="title">
        <FormattedMessage id="register.title" />
      </h1>
      <span className='register_subtitle'><FormattedMessage id="register.subtitle" /></span>

      <div className="row">
        <label>
          <input
            required
            type="text"
            name="formHow"
            autoComplete="organization"
            placeholder={intl.formatMessage({ id: 'register.form-how' })}
            value={formHow}
            onChange={(e) => setFormHow(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formLastName"
            placeholder={intl.formatMessage({ id: 'register.form-lastName' })}
            value={formLastName}
            onChange={(e) => setFormLastName(e.target.value)}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formName"
            placeholder={intl.formatMessage({ id: 'register.form-name' })}
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formPhone"
            placeholder={intl.formatMessage({ id: 'register.form-phone' })}
            value={formPhone}
            onChange={(e) => setformPhone(e.target.value)}
          />
        </label>
        <label>
          <input
            type="email"
            required
            name="formMail"
            placeholder={intl.formatMessage({ id: 'register.form-mail' })}
            value={formMail}
            onChange={(e) => setFormMail(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formJob"
            placeholder={intl.formatMessage({ id: 'register.form-job' })}
            value={formJob}
            onChange={(e) => setFormJob(e.target.value)}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formCompany"
            placeholder={intl.formatMessage({ id: 'register.form-company' })}
            value={formCompany}
            onChange={(e) => setFormCompany(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formNIF"
            placeholder={intl.formatMessage({ id: 'register.form-nif' })}
            value={formNIF}
            onChange={(e) => setFormNIF(e.target.value)}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formCity"
            placeholder={intl.formatMessage({ id: 'register.form-city' })}
            value={formCity}
            onChange={(e) => setFormCity(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formCompanyPhrase"
            placeholder={intl.formatMessage({ id: 'register.form-companyPhrase' })}
            value={formCompanyPhrase}
            onChange={(e) => setFormCompanyPhrase(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formWeb"
            placeholder={intl.formatMessage({ id: 'register.form-web' })}
            value={formWeb}
            onChange={(e) => setFormWeb(e.target.value)}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formBilling"
            placeholder={intl.formatMessage({ id: 'register.form-billing' })}
            value={formBilling}
            onChange={(e) => setFormBilling(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formExport"
            placeholder={intl.formatMessage({ id: 'register.form-export' })}
            value={formExport}
            onChange={(e) => setFormExport(e.target.value)}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formEmployees"
            placeholder={intl.formatMessage({ id: 'register.form-employees' })}
            value={formEmployees}
            onChange={(e) => setFormEmployees(e.target.value)}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formExportCountries"
            placeholder={intl.formatMessage({ id: 'register.form-exportCountries' })}
            value={formExportCountries}
            onChange={(e) => setFormExportCountries(e.target.value)}
          />
        </label>
        <label>
          <select
            required
            name="formCanBuyOnline"
            onChange={(e) => setFormCanBuyOnline(e.target.selectedIndex)}
          >
            <option value="" selected={formCanBuyOnline === 0}>{intl.formatMessage({ id: 'register.form-canBuyOnline' })}</option>
            {buyOnline.map((c, i) => (
              <option value={c} key={i} selected={formCanBuyOnline === i+1}>{c}</option>
            ))}
          </select>
        </label>
      </div>

      <div className="row">
        <select
          required
          name="formBusinessModel"
          onChange={(e) => setFormBusinessModel(e.target.selectedIndex)}
        >
          <option value="" selected={formBusinessModel === 0}>{intl.formatMessage({ id: 'register.form-businessModel' })}</option>
          {businessModel.map((c, i) => (
            <option value={c} key={i} selected={formBusinessModel === i+1}>{c}</option>
          ))}
        </select>
        <select
          required
          name="formAttendingCity"
          onChange={(e) => setFormAttendingCity(e.target.selectedIndex)}
        >
          <option value="" selected={formAttendingCity === 0}>{intl.formatMessage({ id: 'register.form-attendingCity' })}</option>
          {cityNames.map((c, i) => (
            <option value={c} key={i} selected={formAttendingCity === i+1}>{c}</option>
          ))}
        </select>
      </div>

      <div className="row">
        <label>
          <textarea
            name="formComments"
            placeholder={intl.formatMessage({ id: 'register.form-comments' })}
            value={formComments}
            onChange={(e) => setFormComments(e.target.value)}
          />
        </label>
      </div>

      <p>
        <FormattedMessage
          id="register.text"
          values={{
            privacyPolicy: <a href="/privacy" target="_blank" rel="noopener noreferrer">política de privacidad</a>,
          }}
        />
      </p>
      <div className="row privacy-checkbox">
        <label>
          <input
            type="checkbox"
            name="formCheckbox"
            id="register_checkbox"
            checked={formCheckbox}
            onChange={() => setFormCheckbox(!formCheckbox)}
          />
          <FormattedMessage id="register.checkbox" />
        </label>
      </div>
      {status === 'error' ? (
        <div>
          <p className="messageError"><FormattedMessage id="register.error" /></p>
        </div>
      ) : ''}
      <button type="submit" className="filled"><FormattedMessage id="send" /></button>
    </form>

  return (
    <section id="register" className={onView ? 'onView' : ''}>
      <header className="header-steps">
        {steps.map((s, i) => (
          <div key={s} className={`header-step step-${s}`}>
            <span className={`icon icon-${s}`} />
            <span className="name">
              <FormattedMessage id={`register.step-${s}`} />
            </span>
            {i + 1 !== steps.length && <span className="icon icon-arrow" />}
          </div>
        ))}
      </header>
      {status === 'loading' ?
        <Loading /> :
        status === 'success' ?
          (
            <div className="formContainer">
              <p className='successMessage'><FormattedMessage id="register.success" /></p>
            </div>
          ) :
          (
            form
          )
      }
    </section>
  )
}

export default Register
