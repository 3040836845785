import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import Header from 'src/components/Header/Header'
import Typewriter from './Typewriter/Typewriter'
import PoweredBy from 'src/components/PoweredBy/PoweredBy'
import Footer from 'src/components/Footer/Footer'
import CEO from './CEO/CEO'
import Intro from './Intro/Intro'
import Why from './Why/Why'
import Who from './Who/Who'
import './Home.sass'
import Requirements from './Requirements/Requirements'
import Register from 'src/components/Register/Register'
import Banner from './Banner/Banner'
import Program from './Program/Program'
import Calendar from 'src/components/Calendar/Calendar'
import Companies from './Companies/Companies'
import Speakers from './Speakers/Speakers'
import { useEffect } from 'react';

const Home = () => {
  const scrollable = useRef(null)
  const bannerSection = useScrollOver('#banner', scrollable)
  const introSection = useScrollOver('#intro', scrollable)
  const CEOSection = useScrollOver('#ceo-quests', scrollable)
  const whySection = useScrollOver('#why-participate', scrollable)
  const whoSection = useScrollOver('#who-are', scrollable)
  const requirementsSection = useScrollOver('#requirements', scrollable)
  const registerSection = useScrollOver('#register', scrollable)
  const speakersSection = useScrollOver('#speakers', scrollable)
  const programSection = useScrollOver('#program', scrollable)
  const calendarSection = useScrollOver('#calendar', scrollable)
  const companiesSection = useScrollOver('#companies', scrollable)

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Elimina el "#" inicial
    if (hash) {
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id="home" key="home" ref={scrollable} className="page">
      <Header page="home" scrollable={scrollable} />
      <section id="hero-banner">
        <div className="hero-banner-wrapper">
          <Typewriter />
          <a
            href="#register"
            className="button"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection('register');
            }}
          >
            <FormattedMessage id="header.link.subscribe" />
          </a>
        </div>
        <div className="powered-wrapper">
          <PoweredBy withText />
        </div>
      </section>
      <Intro onView={introSection > 0.3} />
      <CEO onView={CEOSection > 0.15} />
      <Who onView={whoSection > 0.3} />
      <Why onView={whySection > 0.3} />
      <Banner onView={bannerSection > 0.2} />
      <Companies onView={companiesSection > 0.15} />
      <Speakers onView={speakersSection > 0.05} />
      <Program onView={programSection > 0.1} />
      <Calendar onView={calendarSection > 0.15} />
      <Requirements onView={requirementsSection > 0.3} />
      <Register onView={registerSection > 0.15} />
      <Footer />
    </div>
  )
}

export default Home
