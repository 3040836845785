import alberto from 'src/static/images/SpeakersPhotos/Alberto_Turégano.jpg'
import alejandro from 'src/static/images/SpeakersPhotos/Alejandro_Orviz_Bluco.jpg'
import alvaro from 'src/static/images/SpeakersPhotos/Alvaro de la Cueva Garrigues.jpg'
import ana from 'src/static/images/SpeakersPhotos/ANA_SANTIAGO.jpg'
import arturo from 'src/static/images/SpeakersPhotos/FotografiaArturoGuerreroIBM.jpg'
import biladi from 'src/static/images/SpeakersPhotos/Biladi Lujan Sakudarte.jpg'
import carlos from 'src/static/images/SpeakersPhotos/Carlos Garcia castellano_FOTO.jpg'
import cesar from 'src/static/images/SpeakersPhotos/Foto cesar mariel.jpg'
import cesarT from 'src/static/images/SpeakersPhotos/foto_cesar-tello_adigital.jpg'
import daniel from 'src/static/images/SpeakersPhotos/Daniel_Puertolas_Numericco.jpg'
import dori from 'src/static/images/SpeakersPhotos/Dori.jpg'
import eduardo from 'src/static/images/SpeakersPhotos/Eduardo Canelles_Digital Tatics.jpg'
import eduardoL from 'src/static/images/SpeakersPhotos/Eduardo_Lazcano.jpg'
import fermin from 'src/static/images/SpeakersPhotos/Fermín_Manzanedo.jpg'
import fernando from 'src/static/images/SpeakersPhotos/Fernando.jpg'
import fernandoG from 'src/static/images/SpeakersPhotos/Fernando_Garcia_BHub.jpg'
import hector from 'src/static/images/SpeakersPhotos/HectorRobles.jpg'
import jaime from 'src/static/images/SpeakersPhotos/Jaime Castillo_Shakers.jpg'
import javier from 'src/static/images/SpeakersPhotos/Foto Javier Cuervo.jpg'
import javierM from 'src/static/images/SpeakersPhotos/Javier_Martin_Integrarobot.jpg'
import javierMartinez from 'src/static/images/SpeakersPhotos/Javier_Martinez.jpg'
import jose from 'src/static/images/SpeakersPhotos/José-López_Igeneris.jpg'
import joseLuisAlonso from 'src/static/images/SpeakersPhotos/José Luis Alonso Gallardo.jpg'
import joseLuisVal from 'src/static/images/SpeakersPhotos/foto jose luis del val.jpg'
import joseLuisPulpon from 'src/static/images/SpeakersPhotos/Jose Luis Pulpón_Making Science.jpg'
import justo from 'src/static/images/SpeakersPhotos/JustoHidalgo.headshot.HD.jpg'
import leticia from 'src/static/images/SpeakersPhotos/Leticia Gómez_Minsait.jpg'
import lucia from 'src/static/images/SpeakersPhotos/Lucía Marínez_WAM.jpg'
import luis from 'src/static/images/SpeakersPhotos/Luis Diaz del Dedo_ Product Hackers.jpg'
import marta from 'src/static/images/SpeakersPhotos/Marta-Cordoba_AWS.jpg'
import monica from 'src/static/images/SpeakersPhotos/Monica Diaz Ponte.jpg'
import nacho from 'src/static/images/SpeakersPhotos/Nacho.jpg'
import nuri from 'src/static/images/SpeakersPhotos/Nuri_Demirci_Microsoft.jpg'
import oscar from 'src/static/images/SpeakersPhotos/Oscar Méndez_Stratio.jpg'
import paloma from 'src/static/images/SpeakersPhotos/Paloma Velázquez_Genetsis.jpg'
import sergio from 'src/static/images/SpeakersPhotos/Sergio_Gordillo_Improven.jpg'
import tiffany from 'src/static/images/SpeakersPhotos/Tiffany_de_la_Torre_Edefense.jpg'
import tirso from 'src/static/images/SpeakersPhotos/Foto tirso maldonado.jpg'
import valentin from 'src/static/images/SpeakersPhotos/Valentín_Barrantes_Nóvalo.jpg'

export default {
    alberto,
    alejandro,
    alvaro,
    ana,
    arturo,
    biladi,
    carlos,
    cesar,
    cesarT,
    daniel,
    dori,
    eduardo,
    eduardoL,
    fermin,
    fernando,
    fernandoG,
    hector,
    jaime,
    javier,
    javierM,
    javierMartinez,
    jose,
    joseLuisAlonso,
    joseLuisVal,
    joseLuisPulpon,
    justo,
    leticia,
    lucia,
    luis,
    marta,
    monica,
    nacho,
    nuri,
    oscar,
    paloma,
    sergio,
    tiffany,
    tirso,
    valentin
}
