import React from 'react'
import { FormattedMessage } from 'react-intl'

import './CalendarFormation.sass'

const CalendarFormation = ({ cities, setCityId, mapRef }) => {

  const handleCityClick = (cityId) => {
    setCityId(cityId);
    if (mapRef && mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="calendar-formation">
      <h3><FormattedMessage id="calendar.calendar-formation.title" /></h3>
      <ul>
        {cities.map(c => (
          <li key={c.id} 
          onClick={() => handleCityClick(c.id)} 
          className='cityButton'>
            <strong className="name">{c.name}</strong>
            <div className="date">
              <span className="icon icon-calendar" />
              <div className="dates">
                <span>{c.start} {c.year}</span>
                <span>{c.end} {c.year}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CalendarFormation