import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import MarkdownMessage from '@gluedigital/markdown-message';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';

import './Privacy.sass';

const Privacy = () => {
  const indexList = Array.from({ length: 7 }, (_, i) => (
    <li key={`privacy-list-${i + 1}`}>
      <a href={`#section-${i + 1}`}>
        <FormattedMessage id={`privacy.list-${i + 1}`} />
      </a>
    </li>
  ));

  const section1List = Array.from({ length: 3 }, (_, i) => (
    <li key={`privacy-list-1-text-${i + 2}`}>
      - <MarkdownMessage id={`privacy.list-1-text-${i + 2}`} />
    </li>
  ));

  const section2List = Array.from({ length: 6 }, (_, i) => (
    <li key={`privacy-list-2-text-${i + 2}`}>
      - <MarkdownMessage id={`privacy.list-2-text-${i + 2}`} />
    </li>
  ));

  const section3List = Array.from({ length: 3 }, (_, i) => (
    <li key={`privacy-list-3-text-${i + 2}`}>
      - <MarkdownMessage id={`privacy.list-3-text-${i + 2}`} />
    </li>
  ));

  const section4List1 = Array.from({ length: 5 }, (_, i) => (
    <li key={`privacy-list-4-text-${i + 2}`}>
      - <MarkdownMessage id={`privacy.list-4-text-${i + 2}`} />
    </li>
  ));

  const section6List1 = Array.from({ length: 9 }, (_, i) => (
    <li key={`privacy-list-6-text-${i + 1}`}>
      <MarkdownMessage id={`privacy.list-6-text-${i + 1}`} />
    </li>
  ));

  const section6List2 = Array.from({ length: 3 }, (_, i) => (
    <li key={`privacy-list-6-text-${i + 10}`}>
      - <MarkdownMessage id={`privacy.list-6-text-${i + 10}`} />
    </li>
  ));

  const section7List1 = Array.from({ length: 3 }, (_, i) => (
    <li key={`privacy-list-7-text-${i + 1}`}>
      <MarkdownMessage id={`privacy.list-7-text-${i + 1}`} />
    </li>
  ));

  const section7List2 = Array.from({ length: 6 }, (_, i) => (
    <li key={`privacy-list-7-text-${i + 4}`}>
      - <MarkdownMessage id={`privacy.list-7-text-${i + 4}`} />
    </li>
  ));

  const scrollable = useRef(null)

  return (
    <div id="privacy" ref={scrollable} className="page">
      <Header page="privacy" scrollable={scrollable} />
      <section id="info">
        <h1 className="title">
          <FormattedMessage id="privacy.title" />
        </h1>
        <p><MarkdownMessage id="privacy.intro-1" /></p>
        <p><FormattedMessage id="privacy.intro-2" /></p>
        <ul className="listItems">
          {indexList}
        </ul>

        <h2 id="section-1">
          <FormattedMessage id="privacy.list-1" />
        </h2>
        <p><FormattedMessage id="privacy.list-1-text-1" /></p>
        <ul className="sectionList">
          {section1List}
        </ul>
        <p><FormattedMessage id="privacy.list-1-text-5" /></p>

        <h2 id="section-2">
          <FormattedMessage id="privacy.list-2" />
        </h2>
        <p><FormattedMessage id="privacy.list-2-text-1" /></p>
        <ul className="sectionList">
          {section2List}
        </ul>

        <h2 id="section-3">
          <FormattedMessage id="privacy.list-3" />
        </h2>
        <p><FormattedMessage id="privacy.list-3-text-1" /></p>
        <ul className="sectionList">
          {section3List}
        </ul>

        <h2 id="section-4">
          <FormattedMessage id="privacy.list-4" />
        </h2>
        <p><FormattedMessage id="privacy.list-4-text-1" /></p>
        <ul className="sectionList">
          {section4List1}
        </ul>
        <p><FormattedMessage id="privacy.list-4-text-7" /></p>
        <ul className="sectionList">
          <p>
            - <a href="https://support.google.com/chrome/answer/2790761" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy.list-4-text-8" />
            </a>
          </p>
          <p>
            - <a href="https://support.mozilla.org/es/kb/como-activo-la-funcion-no-rastrear?redirectslug=como-activo-la-caracteristica-no-quiero-ser-rastreado&redirectlocale=es" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy.list-4-text-9" />
            </a>
          </p>
          <p>
            - <a href="https://support.microsoft.com/es-es/windows/usar-no-realizar-seguimiento-en-internet-explorer-11-ad61fa73-d533-ce96-3f64-2aa3a332e792" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy.list-4-text-10" />
            </a>
          </p>
          <p>
            - <a href="https://support.apple.com/es-es/guide/safari/sfri40732/mac" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy.list-4-text-11" />
            </a>
          </p>
          <p>
            - <FormattedMessage id="privacy.list-4-text-12" />
          </p>
          <p>
            - <a href="https://support.google.com/chrome/answer/2790761" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy.list-4-text-13" />
            </a>
          </p>
          <p>
            - <a href="https://support.mozilla.org/es/kb/como-habilito-la-caracteristica-no-quiero-ser-rast" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="privacy.list-4-text-14" />
            </a>
          </p>
        </ul>
        <p><FormattedMessage id="privacy.list-4-text-15" /></p>
        <p><FormattedMessage id="privacy.list-4-text-16" /></p>

        <h2 id="section-5">
          <FormattedMessage id="privacy.list-5" />
        </h2>
        <p><FormattedMessage id="privacy.list-5-text-1" /></p>
        <p><FormattedMessage id="privacy.list-5-text-2" /></p>

        <h2 id="section-6">
          <FormattedMessage id="privacy.list-6" />
        </h2>
        <ul className="sectionList">
          {section6List1}
        </ul>
        <ul className="sectionList">
          {section6List2}
        </ul>

        <h2 id="section-7">
          <FormattedMessage id="privacy.list-7" />
        </h2>
        <ul className="sectionList">
          {section7List1}
        </ul>
        <ul className="sectionList">
          {section7List2}
        </ul>
      </section>
      <Footer />
    </div>
  );
};

export default Privacy;
