import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'
import Register from 'src/components/Register/Register'

import './Program.sass'

const Program = () => {
  const scrollable = useRef(null)
  const registerSection = useScrollOver('#register', scrollable)

  const questCards = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]

  const cardsTexts = [
    '5',
    '5',
    '3',
    '5',
    '4',
    '4',
    '3'
  ]

  return (
    <div id="program" key="program" ref={scrollable} className="page">
      <Header scrollable={scrollable} />
      <section id="info">
        <h1><FormattedMessage id="program.title" /></h1>
        <ul className="container program-grid">
          {questCards.map((q, index) => {
            const numTexts = parseInt(cardsTexts[index], 10);
            return (
              <li key={q} className="program-card">
                <h2><MarkdownMessage id={`program.card-${q}.title`} /></h2>
                {Array.from({ length: numTexts }).map((_, n) => {
                  return (
                    <p key={n}>
                      <span className="icon icon-arrow" />
                      <span><FormattedMessage id={`program.card-${q}.text-${n + 1}`} /></span>
                    </p>
                  )
                })}
              </li>
            )
          })}
        </ul>
      </section>
      <Register onView={registerSection > 0.2} />
      <Footer />
    </div>
  )
}

export default Program