  import React, { useState, useRef } from 'react'
  import { FormattedMessage } from 'react-intl'
  import CalendarFormation from './CalendarFormation/CalendarFormation'
  import CalendarHeader from './CalendarHeader/CalendarHeader'
  import CalendarSideBar from './CalendarSideBar/CalendarSideBar'
  import data from 'src/data'
  import map from 'src/static/svg/map/Ciudades-icex.svg'

  import './Calendar.sass'

  const Calendar = ({ onView = false }) => {
    const [cityId, setCityId] = useState(-1)
    const { cities } = data
    const cityData = cityId === -1 ? null : cities.find(c => c.id === cityId)

    const mapRef = useRef(null)

    const handleCityChange = (id) => {
      setCityId(id)
      if (mapRef && mapRef.current) {
        mapRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return (
      <section id="calendar" className={onView ? 'onView' : ''} >
        <h1 className="title"><FormattedMessage id="calendar-page.title" /></h1>
        <div className="container">
          <CalendarHeader />
          <div className="split">
            <div className="item item-left" ref={mapRef}>
              <CalendarSideBar
                cities={cities}
                cityData={cityData}
                setCityId={handleCityChange}
              />
            </div>
            <div className="item item-right">
              {cityId === -1 ? (
                <img src={map} alt="All cities map" />
              ) : (
                cityData && <img src={`/svg/map/${cityData.mapSrc}`} alt="map illustration" />
              )}
            </div>
          </div>
          <CalendarFormation
            cities={cities}
            setCityId={setCityId}
            mapRef={mapRef}
          />
        </div>
      </section>
    )
  }

  export default Calendar
