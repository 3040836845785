import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'

import './Program.sass'

const Program = ({ onView }) => {

    const questCards = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
    ]

    const cardsTexts = [
        '4',
        '4',
        '4',
        '4',
        '4',
        '4',
        '3'
    ]

    return (
        <section id="program" className={onView ? 'onView' : ''}>
            <div className='container'>
                <h1 className="title"><FormattedMessage id="program.title" /></h1>
                <p className='program-subtitle'><MarkdownMessage id="program.subtitle" /></p>
                <ul className="container program-grid">
                    {questCards.map((q, index) => {
                        const numTexts = parseInt(cardsTexts[index], 10);
                        return (
                            <li key={q} className="program-card">
                                <h2><MarkdownMessage id={`program.card-${q}.title`} /></h2>
                                {Array.from({ length: numTexts }).map((_, n) => {
                                    return (
                                        <p key={n}>
                                            <span className="icon icon-arrow" />
                                            <span><FormattedMessage id={`program.card-${q}.text-${n + 1}`} /></span>
                                        </p>
                                    )
                                })}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </section>
    )
}

export default Program